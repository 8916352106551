.title {

  text-align: center;
  font-size: 100px;
  margin-bottom: 5%;
  margin-top: 80px;

}

.options {
  text-align: center;
  border-color: #e7e7e7;
  background-color: transparent;
  color: #e7e7e7;
  font-size: 40px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 70px;
}
/*
.options:hover {
  background: #e7e7e7;
  color: black;
} */

.flash {
  animation-duration: 4s;
}

.fadeIn1 {
  animation-name: fadeIn;
  animation-delay: 0.5s;
}
.fadeIn2 {
  animation-name: fadeIn;
  animation-delay: 1s;
}

.fadeIn3 {
  animation-name: fadeIn;
  animation-delay: 1.5s;
}
