.code {
  font-size: 50px;
  position: fixed;
  top: 25%;
  right: 48%;
  color: white;
}

.start {
  text-align: center;
  border-color: #e7e7e7;
  background-color: transparent;
  color: #e7e7e7;
  font-size: 40px;
  border-radius: 10px;
  cursor: pointer;
  position: fixed;
  top: 37%;
  right: 45%;
}

.link1 {
  text-align: center;
  font-size: 30px;
  color: white;
  position: fixed;
  top: 48%;
  right: 40%;
  font-family: Arial;
}

.link2 {
  text-align: center;
  font-size: 30px;
  color: white;
  position: fixed;
  top: 51%;
  right: 35%;
  font-family: Arial;
}
