@font-face {
  font-family: "EraserDust";
  src: local("EraserDust"), url(../../Fonts/EraserDust.ttf) format("woff");
}
.text {
  text-align: center;
  font-size: 90px;
  color: white;
  cursor: pointer;

  font-family: "EraserDust";
  margin: 20%;
}

.plusSign {
  text-align: center;
  margin-left: 50%;
  margin-bottom: 10%;
  /* animation: App-logo-spin infinite 5s linear; */

  height: 40px;
  width: 20px;
  background-size: 10%;
  font-size: 100px;
  color: white;
}

.minusSign {
  text-align: center;
  margin-left: 70%;

  animation: App-logo-spin infinite 5s linear;
  height: -50px;
  width: 20px;
  background-size: 10%;
  font-size: 100px;
  color: white;
}

.body {
  font-family: "EraserDust";
  background-image: url("../../chalkboard.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-position: center;
  width: 100%;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Color-sign {
  from {
    color: blue;
  }
  to {
    color: white;
  }
}
